// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
// import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';
import AOS from 'aos';
/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  const languagesBtn = document.querySelector('.header__languages-btn');
  if (languagesBtn) {
    languagesBtn.addEventListener('click', (e) => {
      e.preventDefault();
      languagesBtn.classList.toggle('active');
      languagesBtn.nextElementSibling.classList.toggle('active');
    });
  }
  try {
    let anchors = document.querySelectorAll('a[href^="#"]');
    if (anchors) {
      anchors.forEach((anchor) => {
        anchor.addEventListener('click', function (e) {
          e.preventDefault();
          document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth',
          });
        });
      });
    }
  } catch (e) {
    console.log(e);
  }
  let minLargeMedia = window.matchMedia('(min-width: 960px)');
  if (minLargeMedia.matches) {
    moveHeaderBehindNav();
  }
  $('.matchHeight').matchHeight();
  AOS.init({
    useClassNames: true,
    initClassName: false,
    animatedClassName: 'visible',
  });
  customTextareaInnit();
  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  $('.newsletter-accordion__btn').on('click', function () {
    $(this).closest('.newsletter-accordion').toggleClass('active');
  });
  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here
  if ($('.ginput_container_select').length) {
    $('.ginput_container_select select').on(
      'focus focusout change',
      function (e) {
        switch (e.type) {
          case 'focus':
            $(this)
              .closest('.ginput_container_select')
              .addClass('open')
              .removeClass('close');
            break;
          case 'focusout':
          case 'change':
            $(this)
              .closest('.ginput_container_select')
              .addClass('close')
              .removeClass('open');
            break;
        }
      }
    );
  }
  if ($('.ginput_address_state').length) {
    $('.ginput_address_state select').on('focus focusout change', function (e) {
      switch (e.type) {
        case 'focus':
          $(this)
            .closest('.ginput_address_state')
            .addClass('open')
            .removeClass('close');
          break;
        case 'focusout':
        case 'change':
          $(this)
            .closest('.ginput_address_state')
            .addClass('close')
            .removeClass('open');
          break;
      }
    });
  }
  addObserverToSelect();
  numberAnimationInnit();
  videoPlayerInnit();
  employeesFormAJAXInit();
  formTabsInit();
  if ($('.grid-two__block').length) {
    $('.grid-two__block').each(function (index) {
      if (index === 0) {
        $(this).slick({
          arrows: false,
          autoplay: true,
          infinite: true,
          fade: true,
          speed: 1250,
          cssEase: 'linear',
          autoplaySpeed: 9990,
        });
      }
      if (index === 1) {
        $(this).slick({
          arrows: false,
          autoplay: true,
          infinite: true,
          fade: true,
          speed: 1250,
          cssEase: 'linear',
          autoplaySpeed: 8860,
          initialSlide: 1,
        });
      }
      if (index === 2) {
        $(this).slick({
          arrows: false,
          autoplay: true,
          infinite: true,
          fade: true,
          speed: 1250,
          cssEase: 'linear',
          autoplaySpeed: 7480,
          initialSlide: 2,
        });
      }
      if (index === 3) {
        $(this).slick({
          arrows: false,
          autoplay: true,
          infinite: true,
          fade: true,
          speed: 1250,
          cssEase: 'linear',
          autoplaySpeed: 6340,
          initialSlide: 3,
        });
      }
      if (index === 4) {
        $(this).slick({
          arrows: false,
          autoplay: true,
          infinite: true,
          fade: true,
          speed: 1250,
          cssEase: 'linear',
          autoplaySpeed: 5990,
          initialSlide: 4,
        });
      }
      if (index === 5) {
        $(this).slick({
          arrows: false,
          autoplay: true,
          infinite: true,
          fade: true,
          speed: 1250,
          cssEase: 'linear',
          autoplaySpeed: 4860,
          initialSlide: 5,
        });
      }
      if (index === 6) {
        $(this).slick({
          arrows: false,
          autoplay: true,
          infinite: true,
          fade: true,
          speed: 1250,
          cssEase: 'linear',
          autoplaySpeed: 3480,
          initialSlide: 6,
        });
      }
      if (index === 7) {
        $(this).slick({
          arrows: false,
          autoplay: true,
          infinite: true,
          fade: true,
          speed: 1250,
          cssEase: 'linear',
          autoplaySpeed: 2340,
          initialSlide: 7,
        });
      }
    });
    // $('.grid-two__block').slick({
    //   arrows: false,
    //   autoplay: true,
    //   infinite: true,
    //   fade: true,
    //   cssEase: 'linear',
    // });
    // $('.grid-two__block').each(function (index) {
    //   if (index === 0) {
    //     $(this)[0].slick.options.autoplaySpeed = 9990;
    //   }
    //   if (index === 1) {
    //     $(this)[0].slick.options.initialSlide = 1;
    //     $(this)[0].slick.options.autoplaySpeed = 8860;
    //   }
    //   if (index === 2) {
    //     $(this)[0].slick.options.initialSlide = 2;
    //     $(this)[0].slick.options.autoplaySpeed = 7480;
    //   }
    //   if (index === 3) {
    //     $(this)[0].slick.options.initialSlide = 3;
    //     $(this)[0].slick.options.autoplaySpeed = 6340;
    //   }
    //   if (index === 4) {
    //     $(this)[0].slick.options.initialSlide = 4;
    //     $(this)[0].slick.options.autoplaySpeed = 5990;
    //   }
    //   if (index === 5) {
    //     $(this)[0].slick.options.initialSlide = 5;
    //     $(this)[0].slick.options.autoplaySpeed = 4860;
    //   }
    //   if (index === 6) {
    //     $(this)[0].slick.options.initialSlide = 6;
    //     $(this)[0].slick.options.autoplaySpeed = 3480;
    //   }
    //   if (index === 7) {
    //     $(this)[0].slick.options.initialSlide = 7;
    //     $(this)[0].slick.options.autoplaySpeed = 2340;
    //   }
    // });
  }
  if ($('.event-slider').length) {
    $('.event-slider').slick({
      dots: false,
      infinite: true,
      speed: 500,
      fade: true,
      cssEase: 'linear',
      nextArrow: $('.event-slider')
        .closest('.acf-quicklinks-and-events-block__events-cell')
        .find('span.next'),
      prevArrow: $('.event-slider')
        .closest('.acf-quicklinks-and-events-block__events-cell')
        .find('span.prev'),
    });
  }
  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
  if ($('.header-one__heading').length) {
    $('.header-one__heading').html(function () {
      let text = $(this).text().split(' ');
      let last = text.pop();
      return (
        text.join(' ') +
        (text.length > 0
          ? ' <span class="font-color-green">' + last + '</span>'
          : last)
      );
    });
  }
});
/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
const headerStaticElem = document.querySelector('.header.static');
const headerActiveElem = document.querySelector('.header.active');
const LARGE_MEDIA = window.matchMedia('(min-width: 960px)');
const headerHeight = headerStaticElem.offsetHeight;
const WPAdminBar = document.getElementById('wpadminbar');
const stickyHeaderContainer = document.querySelector(
  '.sticky-header-container'
);
const bodyElem = document.body;
stickyHeaderContainer.style.height = headerHeight + 'px';

let scrollRef = 0;
window.addEventListener('scroll', function () {
  scrollRef <= 10 ? scrollRef++ : AOS.refresh();
});

document.addEventListener('click', onClickTitleBar);
$(window).on('scroll', function () {
  activeHeaderOnScroll();
});
function activeHeaderOnScroll() {
  if (LARGE_MEDIA.matches) {
    if (window.pageYOffset > headerHeight) {
      stickyHeaderContainer.classList.add('visable');
      headerActiveElem.classList.add('visable');
      headerStaticElem.classList.remove('visable');
      if (WPAdminBar) {
        headerActiveElem.style.top = WPAdminBar.clientHeight + 'px';
      }
    } else {
      headerActiveElem.classList.remove('visable');
      stickyHeaderContainer.classList.remove('visable');
      headerStaticElem.classList.add('visable');
    }
  }
}
function onClickTitleBar(event) {
  if (event.target.closest('.title-bar')) {
    bodyElem.classList.toggle('menu-open');
  }
}
function videoPlayerInnit() {
  if ($('.video-preview').length) {
    $('.video-preview').on('click', function (e) {
      if (
        e.target
          .closest('.video-preview')
          .nextElementSibling.classList.contains('video-popup')
      ) {
        e.target
          .closest('.video-preview')
          .nextElementSibling.classList.add('show');
        let videoELem = e.target
          .closest('.video-preview')
          .nextElementSibling.querySelector('video');
        if (videoELem) {
          videoELem.muted = false;
          videoELem.play();
        }
        let videoPopupElem =
          e.target.closest('.video-preview').nextElementSibling;
        if (
          videoPopupElem.classList.contains('youtube') ||
          videoPopupElem.classList.contains('vimeo')
        ) {
          let frame = videoPopupElem.querySelector('iframe');
          let src = frame.getAttribute('src');
          frame.setAttribute('src', src + '?autoplay=1');
        }
      }
    });
  }

  if ($('.video-popup__close-button').length) {
    $('.video-popup__close-button').on('click', function (e) {
      if (e.target.closest('.video-popup').classList.contains('show')) {
        e.target.closest('.video-popup').classList.remove('show');
        // e.target.closest('.video-popup').querySelector('video').pause();
        let HTMLvideoPlayer = e.target
          .closest('.video-popup')
          .querySelector('video');
        if (HTMLvideoPlayer) {
          HTMLvideoPlayer.muted = true;
        }
      }
      let videoPopupElem = e.target.closest('.video-popup');
      if (videoPopupElem.classList.contains('youtube')) {
        let frame = videoPopupElem.querySelector('iframe');
        let src = frame.getAttribute('src');
        frame.setAttribute('src', src + '?autoplay=1');
      }
    });
  }
}
function numberAnimationInnit() {
  let numbers = document.querySelectorAll('.grid-five__number .number');
  if (numbers) {
    numbers.forEach((number) => {
      let numberTop = number.getBoundingClientRect().top;
      window.addEventListener('scroll', function onScroll() {
        if (window.innerHeight > numberTop) {
          this.removeEventListener('scroll', onScroll);
          number.closest('.grid-five__item-cell').classList.add('visible');
        }
      });
    });
  }
}
function employeesFormAJAXInit() {
  if ($('.employee-login__form').length) {
    const form = $('.employee-login__form');
    form.on('submit', function (event) {
      event.preventDefault();
      let login = $('[name="employee_login"]').val();
      let password = $('[name="employee_password"]').val();
      let pageID = $('[name="employee_page_id"]').val();
      if (login && password) {
        /* global ajax_object */
        $.ajax({
          type: 'POST',
          url: ajax_object.ajax_url,
          data: {
            action: 'employee_login_form',
            login: login,
            password: password,
            page_id: pageID,
          },
          success: function (response) {
            console.log(response);
            if (response.logged_in === true) {
              // $('.employee-login__section-content').append(response.html);
              // $('.employee-login__section.login-form').css({ display: 'none' });
              setCookie('employee_logged_in', 'true', 1);
              location.reload();
            } else {
              $('.employee-login__footer-message-error').css({
                display: 'block',
              });
              $('[name="employee_login"]').val('');
              $('[name="employee_password"]').val('');
            }
          },
        });
      }
    });
  }
}
function moveHeaderBehindNav() {
  let header = document.querySelector('header.header.active');
  let headerStatic = document.querySelector('header.header.static');
  if (header) {
    const nextElemAfterHeader = header.nextElementSibling;
    const classNames = [
      'header-one',
      'header-three',
      'header-four',
      'header-five',
      'header-six',
    ];
    if (
      classNames.some((className) =>
        nextElemAfterHeader.classList.contains(className)
      )
    ) {
      nextElemAfterHeader.style.marginTop =
        '-' + (2 + headerStatic.clientHeight) + 'px';
      if (
        window.getComputedStyle(nextElemAfterHeader).backgroundColor ===
          'rgb(0, 72, 83)' ||
        window.getComputedStyle(nextElemAfterHeader).backgroundColor ===
          'rgb(79, 167, 139)' ||
        window.getComputedStyle(nextElemAfterHeader).backgroundColor ===
          'rgb(218, 170, 0)' ||
        window.getComputedStyle(nextElemAfterHeader).backgroundColor ===
          'rgb(74, 47, 70)'
      ) {
        headerStatic.classList.add('white');
      }
    }
  }
}
function setCookie(name, value, days) {
  let expires = '';
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/; Secure';
}
function addObserverToSelect() {
  const selects = document.querySelectorAll(
    '.ginput_container_select .chosen-container'
  );
  if (selects) {
    const options = {
      attributes: true,
    };

    const callback = (mutationList) => {
      mutationList.forEach(function (mutation) {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'class'
        ) {
          if (mutationList[0].target.classList.contains('chosen-with-drop')) {
            mutationList[0].target
              .closest('.ginput_container_select')
              .classList.add('open');
          } else {
            mutationList[0].target
              .closest('.ginput_container_select')
              .classList.remove('open');
          }
        }
      });
    };

    selects.forEach((elem) => {
      const observer = new MutationObserver(callback);
      observer.observe(elem, options);
    });
  }
}
function customTextareaInnit() {
  const textareaElems = document.querySelectorAll('textarea');
  if (textareaElems) {
    textareaElems.forEach((elem) => {
      let textareaLabel = elem.parentElement.previousElementSibling;
      if (textareaLabel.tagName === 'LABEL') {
        textareaLabel.classList.add('textarea-label');
        textareaLabel.parentElement.classList.add('textarea-container');
        textareaLabel.addEventListener('click', () => {
          elem.classList.toggle('open');
          textareaLabel.classList.toggle('open');
        });
      }
    });
    document.addEventListener('click', (event) => {
      let eventTarget = event.target;
      if (!eventTarget.closest('.textarea-container')) {
        textareaElems.forEach((elem) => {
          let textareaLabel = elem.parentElement.previousElementSibling;
          if (textareaLabel.tagName === 'LABEL') {
            textareaLabel.classList.remove('open');
          }
          elem.classList.remove('open');
        });
      }
    });
  }
}
function formTabsInit() {
  const formSwitcher = document.querySelector('.form-block__form-switcher');
  const formTabs = document.querySelectorAll('.form-block__form-tab');
  if (formSwitcher && formTabs) {
    formTabs.forEach((elem) => {
      if (!elem.classList.contains('form-block__form-three')) {
        elem.classList.add('hidden');
      }
    });
    setTimeout(() => {
      if ($('#input_6_1').length) {
        $('#input_6_1').on('change', function (evt, params) {
          formTabs.forEach((elem) => {
            elem.classList.add('hidden');
            if (elem.id === params.selected) {
              if (elem.classList.contains('hidden')) {
                elem.classList.remove('hidden');
              }
            }
          });
        });
      }
    });
  }
}
